@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.row {
  display: flex;
  margin-top: 10px;
}
.tab-title {
  min-width: 80px;
  height: 28px;
  color: rgba(51, 51, 51, 1);
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 28px;
  text-align: center;
  margin-right: 20px;
}
.my-radio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #7f76bd;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .my-radio-choice {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7f76bd;
  }
}
