@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.entryEvidence {
  height: 100%;
  position: relative;
  .top-bar {
    display: flex;
    position: relative;
    .item {
      margin-right: 10px;
      padding: 4px;
      cursor: pointer;
      min-width: 80px;
      text-align: center;
    }
    .item-choice {
      background: rgba(127, 118, 189, 1);
      color: #fff;
      border-radius: 4px;
    }
  }
  .content {
    margin-top: 10px;
    height: calc(100% - 8px);
    overflow-y: scroll;
  }
}
