@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.el-divider {
  background: rgba(236, 234, 250, 1);
}
.tab-title {
  width: 80px;
  height: 24px;
  color: rgba(51,51,51,1);
  background: rgba(236,234,250,1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 24px;
  text-align: center;
  margin-right: 30px;
}
.row {
  display: flex;
}
.eb-text-area {
  margin-top: 10px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid rgba(197,192,224,1);
  height: 250px;
  padding: 0 10px;
}
.tab-top-left {
  background: linear-gradient(
    to top right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    rgba(197,192,224,1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 1px),
    rgba(0, 0, 0, 0) 100%
  )
}
.tab {
  width: 110px;
  height: 36px;
  text-align:center;
  border: 1px solid rgba(199,194,225,1);
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: -1px;
  margin-top: -1px;
}
.zdy-input {
  border: 1px solid rgba(197,192,224,1);
  border-radius: 4px;
  height: 24px;
  width: 30px;
  text-align: center;
}
.bg1 {
  background: rgb(180, 212, 244, 0.5);
}
.bg2 {
  background: rgb(171, 227, 222, 0.5);
}
// 隐藏所有INput框的上下标按钮
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
