@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.my-radio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #7F76BD;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .my-radio-choice {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #7F76BD;
  }
}
