@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.el-divider {
  background: rgba(236, 234, 250, 1);
}
.tab-title {
  width: 80px;
  height: 24px;
  color: rgba(51, 51, 51, 1);
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 24px;
  text-align: center;
  margin-right: 30px;
}
.row {
  display: flex;
}
.eb-text-area {
  margin-top: 10px;
  overflow-y: scroll;
  border-radius: 4px;
  border: 1px solid rgba(197, 192, 224, 1);
  height: 250px;
  padding: 0 10px;
  white-space: pre-wrap;
}
.row-title {
  width: 80px;
  margin-right: 20px;
}
